import React, { useState } from "react";

const EditLeftbar = ({ selectLeftBar, setSelectLeftBar,formData }) => {
  const [editLeft,setEditLeft]=useState([
    {
      id: "16",
      title: "Calender",


    },
    {
      id: "10",
      title: "Price and discounts",
    },
    {
      id: "3",
      title: "Cars and Plate Number",
    },

    
    {
      id: "2",
      title: "Address",
    },
    {
      id: "5",
      title: "Vehicle insurance",
    },
    {
      id: "8",
      title: "Cancellations conditions",
    },
    {
      id: "4",
      title: "Add more details",
    },
    
    // {
    //   id: "2",
    //   title: "Where will you meet drivers for Region?",
    // },
    // {
    //   id: "3",
    //   title: "What's your plate number?",
    // },
    // {
    //   id: "4",
    //   title: "Add more details",
    // },
    
    // {
    //   id: "6",
    //   title: "Insurance",
    // },
    // {
    //   id: "7",
    //   title: "Service Offered",
    // },
    // {
    //   id: "8",
    //   title: "Cancellations conditions",
    // },
    // {
    //   id: "9",
    //   title: "Car replace",
    // },
    // {
    //   id: "10",
    //   title: "Where will you meet drivers for Region?",
    // },
  
    // {
    //   id: "11",
    //   title: "What's your Duration?",
    // },
    // {
    //   id: "12",
    //   title:
    //     "Make your accommodation stand out from the crowd to get reservations faster and receive your first reviews Promotion Nouvelles annonces,Weekly reduction,Monthly discount",
    // },
    // {
    //   id: "13",
    //   title: "Any other features?",
    // },
    // {
    //   id: "14",
    //   title: "What's your billing address?",
    // },
    // {
    //   id: "15",
    //   title: "Main picture",
    // },
    // {
    //   id: "1",
    //   title: "Earn money by sharing your car with locals",

    // },
  ])
  return (
    <div className="cobalt-CheckmarkField" data-form-checkmark-method="is_pro">
      <div>
        {editLeft?.map((val) => (
          <div
            onClick={() => setSelectLeftBar(val?.id)}
            className={`option-item ${selectLeftBar === val?.id && "selected"}`}
          >
            <div className="option-content">
              <span className="option-title">{val?.title}</span>
            {/* <p className="option-description">
            {
  val?.id === "1" ? "Make, Model, Year, Mileage, Fuel Vehicle Type" :
  val?.id === "2" ? "Location" :
  val?.id === "3" ? "Plate Number, State, Year Of Registration" :
  val?.id === "4" ? "No of doors, No of seats" :
  val?.id === "5" ? "Date, Date of insurance, Expiration date" :
  "Wait for add"
}
    </p> */}
              {/* <p className="option-description">
                Travelers can book automatically.
              </p> */}
              
            </div>
            <span className="option-icon">📝</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditLeftbar;
