import React, { useContext, useState, useRef } from "react";
import "./drivernew.css"
import { DataContext } from "../ContextApi";
import { addCompany, registerDriver } from "../api";
import { useNavigate } from "react-router-dom";
import image1 from "../image/png//main_img.jpg";
import image2 from "../image/png/icon1.png";
import image3 from "../image/png/icon2.png";
import image4 from "../image/png/icon3.png";
import image5 from "../image/png/main_img2.png";
import image6 from "../image/png/main_img6.png";
import quotes from "../image/png/quotes.svg";
import ReusableFeedback from "./ReusableFeedback";
import ReusableFaq from "./ReusableFaq";
const inputFields = [
  // { name: "company_name", label: "MY COMPANY", type: "text", required: true },
  { name: "first_name", label: "FIRST NAME", type: "text", required: true },
  { name: "last_name", label: "LAST NAME", type: "text", required: true },

  {
    name: "user_email",
    label: "EMAIL",
    type: "email",
    required: true,
    placeholder: "your@email.com",
  },
  { name: "user_phone", label: "PHONE", type: "text", required: true },
  {
    name: "user_password",
    label: "PASSWORD",
    type: "password",
    required: true,
  },

  {
    name: "driving_experience",
    label: "EXPERIENCE",
    type: "number",
    required: true,
  },

  // { name: "company_country", label: "COUNTRY", type: "text", required: true },
  // {
  //   name: "company_email",
  //   label: "E-MAIL",
  //   type: "email",
  //   required: true,
  //   placeholder: "your@email.com",
  // },
];

const BusinessSpace2 = () => {
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    user_phone: "",
    user_email: "",
    user_password: "",
    driving_experience: null,
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { openDialog,feedback } = useContext(DataContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = { ...formValues };
    registerDriver(payload, openDialog, navigate);
  };
  const scrollRef = useRef(null);
  const scrollToRef = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    // <body style={{ height: "auto" }} class="v1_body">
      <body style={{ height: "auto", background:"#f1f1f1"}} id="dashboard-cars-show">
  <div class="js_site_content site_content">


    <div class="section main-section section--grid section--center border_none">
      <div class="wr titleAlign-- driver_main">
        <div class="row align-items-center">
          <div class="col-md-6 mb-3">
            <h3>Opportunity is everywhere</h3>
            <p>Make the most of your time on the road on the platform with the largest network of active riders.</p>
            <a href="" class="btn driver_btn">Let’s Talk</a>
          </div>
          <div class="col-md-6 mb-3">
            <img src={image1} alt=""/>
          </div>
        </div> 
        </div>
      </div>
      <div class="section main-section section--grid section--center border_none">
      <div class="wr titleAlign-- ">
          <div class="row mb-4 d-flex justify-content-center">
            <div class="col-md-8 mb-3 make-money">
              <h4>Make money when you want</h4>
              <p>As the leading custom software development company in Atlanta, we offer our clients a wide range of services. Our reliable software development services help companies stay competitive, improve processes and increase business value. From designing websites to developing advanced software applications, we can do it all.</p>            
            </div> 
          </div>
          <div class="row">
          <div class="col-md-4 mb-3">
            <div class="schedule_card">
              <img src={image2} alt=""/>
              <h3>Set your own schedule</h3>
              <p>You’re the boss. You can drive with the Uber app day or night. Fit driving around your life, not the other way around.</p>
            </div>           
          </div>  
          <div class="col-md-4 mb-3">
            <div class="schedule_card">
              <img src={image3} alt=""/>
              <h3>Make money on your terms</h3>
              <p>The more you drive, the more money you can make. When demand is higher than normal, you can make even more.</p>
            </div>            
          </div> 
          <div class="col-md-4 mb-3">
            <div class="schedule_card">
              <img src={image4} alt=""/>
              <h3>Let the app lead the way</h3>
              <p>Just tap and go. You get turn-by-turn directions, suggestions to help you make more money, and 24/7 support.</p>
            </div>            
          </div>        
        </div>
        </div>
      </div>

      <div class="section main-section section--grid section--center border_none">
      <div class="wr titleAlign-- ">
        <div class="Get_started">
          <div class="row">
            <div class="col-md-7 py-4">
              <h3 class="mb-4">Get started</h3>
              <ul>
                <li>
                  <h4>Sign up online</h4>
                  <p>Just tell us which city you'd like to drive in and the type of license you have. We'll email you with your next steps.</p>
                </li>
                <li>
                  <h4>Check driving requirements</h4>
                  <p>All kinds of people are eligible to drive with Uber. Here’s what you need to know if you’re driving in Lahore, Karachi, Islamabad, and other cities.</p>
                </li>
                <li>
                  <h4>Get a vehicle</h4>
                  <p>Which car is right for you? Make sure it meets our standards in Pakistan, and remember that you’ll make more money if you keep your costs low.</p>
                </li>
              </ul>
            </div>
            <div class="col-md-5">
              <img style={{width:"100%"}} class="w-100" src={image5} alt=""/> 
            </div>  
          </div>
        </div>       
        </div>
      </div>

      <div class="section main-section section--grid section--center border_none">
      <div class="wr titleAlign-- ">
        <div class="row Driver-extras">
          <div class="col-md-12 mb-4">
            <h3>Driver extras</h3>
            <p>What we do for you in word</p>            
          </div>
          <div class="col-md-4 mb-4">
            <div class="Driver_card">
              <h4>Get support</h4>
              <p>Let’s make every Uber trip hassle-free. Our support pages can help you set up your account, get started with the app, adjust fares, and much more.</p>
            </div>            
          </div>
          <div class="col-md-4 mb-4">
            <div class="Driver_card">
              <h4>Contact us</h4>
              <p>Got questions? Get answers. Enjoy personal support at an Uber Greenlight Hub in Lahore, Karachi, Islamabad, and other cities.</p>
            </div>            
          </div>
          <div class="col-md-4 mb-4">
            <div class="Driver_card">
              <h4>Drive safely</h4>
              <p>The Uber app is full of features that help you stay safe and confident before, during, and after every trip. And if you need help, Uber gives you 24/7 support.</p>
            </div>           
          </div>         

        </div>
        </div>
      </div>

    

      <div class=" section main-section section--grid section--center border_none">
      <div class="wr titleAlign-- ">
        <div class="row">
          <div class="">
            <div class=" ready col-md-12">
              <h3>Ready to discuss your project?</h3>
              <p>Chat with our team to see what we can do.</p>
              <button                   onClick={() => navigate("/add_driver/1/driver_license")}
 class="btn driver_btn">Get Started</button>     
            </div>  
          </div>    
        </div>
        </div>
      </div>

      <div class="section main-section section--grid section--center border_none">
      <div class="wr titleAlign-- ">
            <div class="col-md-12 feedback_user mb-4">
              <h3>What client love about working with us</h3>
            </div>
            <div class="row align-items-center col-md-12">
              <div class="col-md-6">
                <div class="feedback">
                  <h3>Tansu</h3>
                  <p>
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.”
                  </p>
                  <div class="user_main">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="21"
                        cy="21"
                        r="21"
                        fill="url(#pattern0_3068_5989)"
                      />
                      <defs>
                        <pattern
                          id="pattern0_3068_5989"
                          patternContentUnits="objectBoundingBox"
                          width="1"
                          height="1"
                        >
                          <use transform="scale(0.0185185)" />
                        </pattern>
                      </defs>
                    </svg>
                    <div>
                      <h5>John Doe</h5>
                      <p>Director</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="feedback">
                  <h3>Tansu</h3>
                  <p>
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.”
                  </p>
                  <div class="user_main">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="21"
                        cy="21"
                        r="21"
                        fill="url(#pattern0_3068_5989)"
                      />
                      <defs>
                        <pattern
                          id="pattern0_3068_5989"
                          patternContentUnits="objectBoundingBox"
                          width="1"
                          height="1"
                        >
                          <use transform="scale(0.0185185)" />
                        </pattern>
                      </defs>
                    </svg>
                    <div>
                      <h5>John Doe</h5>
                      <p>Director</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <ReusableFeedback feedback={feedback?.result} />
      <ReusableFaq />
  </div>

    </body>
  );
};

export default BusinessSpace2;
