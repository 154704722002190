import React, { useContext,useState,useEffect } from "react";
import { DataContext } from "../../../ContextApi";

const EditNumberPlate = ({id,formData}) => {
  console.log("formDatanew",formData)
    const {rentYourCar}=useContext(DataContext);
    const [formDataNew, setFormDataNew] = useState({
      plate_number: "",
      state_id:"",
      registration_year: "",
     
    });
    useEffect(() => {
   setFormDataNew({...formDataNew,   plate_number:formData?. vehicle_plate_number,
    state_id:formData?. vehicle_registration_state,
    registration_year:formData?. vehicle_year
    ,})
    }, [formData]);
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormDataNew({
        ...formDataNew,
        [name]: value,
      });
    };
  return (
<>
    <div className="edit-form">
    <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
      Plate number
    </label>
    <input
      type="text"
      name="plate_number"
        value={formDataNew.plate_number}
        onChange={handleInputChange}
      style={{ paddingLeft: "8px" }}
      placeholder="Enter Plate Number"
    />
  </div>
  <div className="edit-form">
    <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
      State
    </label>
    <select
      class="cobalt-SelectField__Input"
      name="state_id"
      id="car_subdivision"
        onChange={handleInputChange}
        value={formDataNew.state_id}
    >
      {rentYourCar?.state?.map((val) => (
        <option value={val.id}>{val.name}</option>
      ))}
    </select>
  </div>
  <div style={{width:"100%"}} className="edit-form">
    <label class="cobalt-FormField__Label labelwidth" for="car_brand_id">
      Year of first registration
    </label>
    <select
      class="cobalt-SelectField__Input"
      name="registration_year"
      id="car_release_year"
        onChange={handleInputChange}
        value={formDataNew?.registration_year}
    >
      {rentYourCar?.year?.map((val) => (
        <option value={val.year_id}>{val.year}</option>
      ))}
    </select>

  </div>
  {/* <div style={{marginLeft:"auto",marginTop:"20px"}} > <button
                              class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
                              data-car-id="1475829"
                              // onClick={handleFormSubmit}
                            >
                              
                              <span>Save</span>
                            </button></div> */}
  </>
);
};

export default EditNumberPlate;
