import React, { useState } from "react";
import { useEffect } from "react";

const EditRentalDuration = ({ formData }) => {
  const [selectedExtraId, setSelectedExtraId] = useState(null);
  const [selectedExtraIdSecond, setSelectedExtraIdSecond] = useState(null);
  const [formDataNew, setFormDataNew] = useState({
    minimum_days: "",
    maximum_days: "",
  });
  const handlePricingChange = (event) => {
    setSelectedExtraId(event.target.value);
  };
  useEffect(() => {
    if (formData?.min_rental_duration) {
      setSelectedExtraId("minimum_days");
      setFormDataNew({
        ...formDataNew,
        minimum_days: formData?.min_rental_duration,
      });
    } else {
      setSelectedExtraId("no_minimum_days");
    }
    if (formData?.max_rental_duration) {
      setSelectedExtraIdSecond("maximum_days");
      setFormDataNew({
        ...formDataNew,
        maximum_days: formData?.max_rental_duration,
      });
    } else {
      setSelectedExtraIdSecond("no_maximum_days");
    }
  }, [formData]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataNew({
      ...formDataNew,
      [name]: value,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      {" "}
      <div class="cobalt-FormField">
        <label class="cobalt-FormField__Label" for="car_transmission">
          Minimum rental duration{" "}
        </label>
        <div
          class="cobalt-CheckmarkField"
          data-form-checkmark-method="transmission"
        >
          <label class="cobalt-CheckmarkField__LabelWrapper">
            <input
              class="cobalt-CheckmarkField__Input"
              type="radio"
              value="minimum_days"
              checked={selectedExtraId === "minimum_days"}
              onChange={handlePricingChange}
              id="car_transmission_1"
            />
            <span class="cobalt-CheckmarkField__Checkmark"></span>
            <span class="cobalt-CheckmarkField__Label">Yes</span>
          </label>
        </div>
        <div
          class="cobalt-CheckmarkField"
          data-form-checkmark-method="transmission"
        >
          <label class="cobalt-CheckmarkField__LabelWrapper">
            <input
              class="cobalt-CheckmarkField__Input"
              type="radio"
              value="no_minimum_days" // Example value, replace with your actual value
              checked={selectedExtraId === "no_minimum_days"} // Adjust this check as per your logic
              onChange={handlePricingChange}
              id="car_transmission_1"
            />
            <span class="cobalt-CheckmarkField__Checkmark"></span>
            <span class="cobalt-CheckmarkField__Label">No</span>
          </label>
        </div>
      </div>
      {selectedExtraId === "no_minimum_days" ? (
        ""
      ) : selectedExtraId === "minimum_days" ? (
        <div class="cobalt-FormField" data-form-field-method="plate_number">
          <label class="cobalt-FormField__Label" for="car_plate_number">
            Maximum number of days{" "}
          </label>
          <div class="cobalt-TextField">
            <input
              required
              class="cobalt-TextField__Input"
              type="number"
              name="minimum_days"
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "+") {
                  e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                }
              }}
              onChange={(e) => {
                const value = e.target.value;

                // Allow only positive numbers
                if (value >= 0) {
                  handleInputChange(e);
                }
              }}
              value={formDataNew?.minimum_days}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div class="cobalt-FormField">
        <label class="cobalt-FormField__Label" for="car_transmission">
          Minimum rental duration{" "}
        </label>
        <div
          class="cobalt-CheckmarkField"
          data-form-checkmark-method="transmission"
        >
          <label class="cobalt-CheckmarkField__LabelWrapper">
            <input
              class="cobalt-CheckmarkField__Input"
              type="radio"
              value="maximum_days"
              checked={selectedExtraIdSecond === "maximum_days"}
              onChange={(e) => setSelectedExtraIdSecond(e.target.value)}
              id="car_transmission_1"
            />
            <span class="cobalt-CheckmarkField__Checkmark"></span>
            <span class="cobalt-CheckmarkField__Label">Yes</span>
          </label>
        </div>
        <div
          class="cobalt-CheckmarkField"
          data-form-checkmark-method="transmission"
        >
          <label class="cobalt-CheckmarkField__LabelWrapper">
            <input
              class="cobalt-CheckmarkField__Input"
              type="radio"
              value="no_maximum_days" // Example value, replace with your actual value
              checked={selectedExtraIdSecond === "no_maximum_days"} // Adjust this check as per your logic
              onChange={(e) => setSelectedExtraIdSecond(e.target.value)}
              id="car_transmission_1"
            />
            <span class="cobalt-CheckmarkField__Checkmark"></span>
            <span class="cobalt-CheckmarkField__Label">No</span>
          </label>
        </div>
      </div>
      {selectedExtraIdSecond === "no_maximum_days" ? (
        ""
      ) : selectedExtraIdSecond === "maximum_days" ? (
        <div class="cobalt-FormField" data-form-field-method="plate_number">
          <label class="cobalt-FormField__Label" for="car_plate_number">
            Minimum number of days{" "}
          </label>
          <div class="cobalt-TextField">
            <input
              required
              class="cobalt-TextField__Input"
              type="number"
              name="maximum_days"
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === "+") {
                  e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                }
              }}
              onChange={(e) => {
                const value = e.target.value;

                if (value >= 0) {
                  handleInputChange(e);
                }
              }}
              value={formData?.maximum_days}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default EditRentalDuration;
