import React, { useContext} from "react";
import { DataContext } from "../../../ContextApi";

import EditDriverPrices from "./EditDriverPrices";
import EditRentalDuration from "./EditRentalDuration";
import EditReduction from "./EditReduction";
import EditFeature from "./EditFeature";
import EditBillingAddress from "./EditBillingAddress";
import EditPicture from "./EditPicture";
import EditCreateCar from "./EditCreateCar";
import EditLocation from "./EditLocation";
import EditNumberPlate from "./EditNumberPlate";
import DoorSeats from "./DoorSeats";
import EditVehicleInsurance from "./EditVehicleInsurance";
import EditServiceOffered from "./EditServiceOffered";
import EditInsurance from "./EditInsurance";
import EditCancelationCondition from "./EditCancelationCondition";
import EditCarReplace from "./EditCarReplace";
import EditUnavailableDates from "./EditUnavailableDates";

const EditRightbar = ({id,selectLeftBar,handleCloseEditModal,formData}) => {
  const {
    rentYourCar,
    
  } = useContext(DataContext);
  return (
    <div>
      <div class="owner_homepage_hero_estimation_form__container js_car_model_estimation_form__container">
      {selectLeftBar==="1"?
      <>
    
       <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
       Meet driver address        </h1>
        <EditLocation/>
       </>
       
      : selectLeftBar==="2"?
      <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Where will you meet drivers for Region?
        </h1>
        <EditLocation/>
        </>
        : selectLeftBar==="3"?
        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
      Earn money by sharing your car with locals
      </h1>
       <EditCreateCar id={id} handleCloseEditModal={handleCloseEditModal} formData={formData}/>
      
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          What's your plate number?{" "}
        </h1>

       <EditNumberPlate id={id} formData={formData}/>
       <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        Main picture

        </h1>
        <EditPicture id={id}/>
       </>
       : selectLeftBar==="4"?
       <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Add more details
        </h1>
     <DoorSeats id={id} formData={formData}/>
     <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        Any other features?

        </h1>
        <EditFeature id={id} formData={formData}/>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        What's your Duration?
        </h1>
        <EditRentalDuration id={id} formData={formData}/>
     </>
     : selectLeftBar==="5"?
     <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Vehicle insurance
        </h1>
       <EditVehicleInsurance id={id} formData={formData} />
       <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Insurance
        </h1>

        <EditInsurance id={id} formData={formData}/>
</>
       : selectLeftBar==="6"?
       <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Insurance
        </h1>

        <EditInsurance id={id} formData={formData}/>
        </>
        : selectLeftBar==="7"?
        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Service Offered
        </h1>
       <EditServiceOffered  id={id} formData={formData}/>
       </>
       : selectLeftBar==="8"?

        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Cancellations conditions
        </h1>
       <EditCancelationCondition id={id} formData={formData} />
       <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Car replace
        </h1>
       <EditCarReplace id={id} formData={formData}/>
       </>
       : selectLeftBar==="9"?
       <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Car replace
        </h1>
       <EditCarReplace id={id} formData={formData}/>
       </>
       : selectLeftBar==="10"?
       <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
Services        </h1>
        <EditServiceOffered  id={id} formData={formData}/>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
          Prices
        </h1>
        <EditDriverPrices id={id} formData={formData}/>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        Make your accommodation stand out from the crowd to get reservations faster and receive your first reviews

        </h1>
        <EditReduction id={id} formData={formData}/>
        </>
        : selectLeftBar==="11"?
        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        What's your Duration?
        </h1>
        <EditRentalDuration id={id} formData={formData}/>
        </>
        : selectLeftBar==="12"?
        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        Make your accommodation stand out from the crowd to get reservations faster and receive your first reviews

        </h1>
        <EditReduction id={id} formData={formData}/>
        </>
        : selectLeftBar==="13"?
        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        Any other features?

        </h1>
        <EditFeature id={id} formData={formData}/>
        </>
        : selectLeftBar==="14"?
        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        What's your billing address?

        </h1>
        <EditBillingAddress id={id} formData={formData}/>
        </>
        : selectLeftBar==="15"?
        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        Main picture

        </h1>
        <EditPicture id={id}/>
        </>
        : selectLeftBar==="16"?
        <>
        <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
       Calender

        </h1>
        <EditUnavailableDates id={id}/>
        </>:""
      }
      </div>
      
    </div>
  );
};

export default EditRightbar;
