import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../ContextApi";
import {  TextareaAutosize } from "@mui/material";

const EditFeature = ({formData,id}) => {
    const {rentYourCar}=useContext(DataContext);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [description,setDescription]=useState("")
    const handleFeatureChange = (e, featureId) => {
      if (e.target.checked) {
        setSelectedFeatures([...selectedFeatures, featureId]);
      } else {
        setSelectedFeatures(selectedFeatures.filter((id) => id !== featureId));
      }
    };
    useEffect(() => {
      setSelectedFeatures(formData?.feature?.map((feature) => feature?.feature_id));
setDescription(formData?.description)
    }, [formData]);
    console.log("selectedFeatures",selectedFeatures)
  return(
    
    <div class="cobalt-FormField">
    <div class="cobalt-CheckablePillsGroup">
      {rentYourCar?.features?.map((val) => (
        <div
          class="cobalt-CheckablePillField cobalt-CheckablePillField--with-icon"
          data-form-checkmark-method="equipments"
        >
          <label
            class="cobalt-CheckablePillField__LabelWrapper"
          
          >
            <input
              id={`checkable-pill-${val.feature_id}`}
              className="cobalt-CheckablePillField__Input"
              type="checkbox"
              name="features"
                                          checked={selectedFeatures.includes(
                                            val.feature_id
                                          )}
                                          onChange={(e) =>
                                            handleFeatureChange(
                                              e,
                                              val.feature_id
                                            )
                                          }
            />
            <span class="cobalt-CheckablePillField__Label">
              <span class="cobalt-Icon cobalt-Icon--car">
               
                <span class="cobalt-Icon cobalt-Icon--car">
                <img src={val?.image_url} style={{width:"20px",height:"20px"}} />
              </span>
              </span>
              {val?.feature_name}
            </span>
          </label>
        </div>
      ))}
    </div>
    <div className="cobalt-TextField">
            <label className="modalLabel" htmlFor="first_name">
              Description
            </label>

            <TextareaAutosize
            style={{paddingLeft:"10px"}}
              name="description"
              
              onChange={(e)=>setDescription(e.target.value)}
         
              value={description}
            />
          </div>
  </div>


  );
};

export default EditFeature;
