import React,{useState} from "react";
import { useEffect } from "react";

const EditReduction = ({formData,id}) => {
  const [discounts, setDiscounts] = useState({
    discountOne: { selected: false, value: 0,id:0 },
    discountTwo: { selected: false, value: 0 ,id:0 },
    discountThree: { selected: false, value: 0,id:0  },
  });

  useEffect(() => {
    // Update discounts based on initialDiscounts prop
    const discountOne = formData?.discounts.find(d => d.discount_id === 1);
    const discountTwo = formData?.discounts.find(d => d.discount_id === 2);
    const discountThree = formData?.discounts.find(d => d.discount_id === 3);

    setDiscounts({
      discountOne: discountOne ? { selected: true, value: discountOne.discount_value, id:discountOne?.discount_id } : { selected: false, value: 0 },
      discountTwo: discountTwo ? { selected: true, value: discountTwo.discount_value ,id:discountTwo?.discount_id} : { selected: false, value: 0 },
      discountThree: discountThree ? { selected: true, value: discountThree.discount_value,id:discountThree?.discount_id } : { selected: false, value: 0 },
    });
  }, [formData]);

  const handleCheckboxChange = (key) => {
    setDiscounts(prev => ({
      ...prev,
      [key]: { ...prev[key], selected: !prev[key].selected }
    }));
  };

  const handleInputChange = (key, value) => {
    setDiscounts(prev => ({
      ...prev,
      [key]: { ...prev[key], value: value >= 0 ? value : 0 }
    }));
  };

  let arr = [
    { id: 1, ...discounts?.discountOne },
    { id: 2, ...discounts?.discountTwo },
    { id: 3, ...discounts?.discountThree },
  ];
  console.log("discount array:", arr.filter((val)=>val?.selected===true));
  return (
  
    <div
    className="cobalt-CheckmarkField"
    data-form-checkmark-method="is_pro"
  >
    <div>
      <div className="discount-option">
        <input
    
          type="checkbox"
          name="discountOne"
          checked={discounts.discountOne.selected}
          onChange={() => handleCheckboxChange("discountOne")}


        />
        <div className="discount-details">
          <span className="discount-percentage">
            <input
              className="input-discount"
              type="number"
              disabled={!discounts.discountOne.selected}
              value={discounts.discountOne.value || ""}
              onKeyDown={(e) => {
                if (["-", "e", "+"].includes(e.key)) e.preventDefault();
              }}
              onChange={(e) => handleInputChange("discountOne", parseInt(e.target.value) || 0)}
            />
            %
          </span>{" "}
          <div className="discount-info">
            <strong>Promotion Nouvelles annonces</strong>
            <p>Offer a % discount on your first 3 bookings</p>
          </div>
        </div>
      </div>

      <div className="discount-option">
        <input
          type="checkbox"
         
          name="discountTwo"
          checked={discounts.discountTwo.selected}
          onChange={() => handleCheckboxChange("discountTwo")}
        />
        <div className="discount-details">
          <span className="discount-percentage">
            <input
              className="input-discount"
              disabled={!discounts.discountTwo.selected}
              value={discounts.discountTwo.value || ""}
              onKeyDown={(e) => {
                if (["-", "e", "+"].includes(e.key)) e.preventDefault();
              }}
              onChange={(e) => handleInputChange("discountTwo", parseInt(e.target.value) || 0)}
              type="number"
            />
            %
          </span>{" "}
          <div className="discount-info">
            <strong>Weekly reduction</strong>
            <p>For stays of 7 nights or more</p>
          </div>
        </div>
      </div>

      <div className="discount-option">
        <input
          type="checkbox"
          name="discountThree"
          checked={discounts.discountThree.selected}
          onChange={() => handleCheckboxChange("discountThree")}
        />
        <div className="discount-details">
          <span className="discount-percentage">
            <input
              className="input-discount"
              disabled={!discounts.discountThree.selected}
              value={discounts.discountThree.value || ""}
              onKeyDown={(e) => {
                if (["-", "e", "+"].includes(e.key)) e.preventDefault();
              }}
              onChange={(e) => handleInputChange("discountThree", parseInt(e.target.value) || 0)}
              type="number"
            />
            %
          </span>{" "}
          <div className="discount-info">
            <strong>Monthly discount</strong>
            <p>For stays of 28 nights or more</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default EditReduction;
