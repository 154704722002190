import React, { useContext } from "react";
import { DataContext } from "../../../ContextApi";
import { useState } from "react";
import { useEffect } from "react";

const EditBillingAddress = ({formData,id}) => {
    const {rentYourCar}=useContext(DataContext)
    const [formDataNew,setFormDataNew]=useState({
      address:"",
      zip:"",
      city:"",
      billing_country:"",
      billing_state:""
      
    })
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormDataNew({
        ...formDataNew,
        [name]: value,
      });
    };
    useEffect(() => {
      setFormDataNew({ address:formData?.billing_address,
        zip:formData?.billing_zipcode,
        city:formData?.billing_country,
        billing_country:formData?.billing_address,
        billing_state:formData?.billing_state
        })
    }, [formData]);
    
  return(
    <>
    <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      name="address"
                                      value={formDataNew?.address}
                                      onChange={handleInputChange}
                                      style={{ paddingLeft: "8px" }}
                                      placeholder="Enter address"
                                    />
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      ZIP code
                                    </label>
                                    <input
                                      type="text"
                                      name="zip"
                                      value={formDataNew?.zip}
                                      onChange={handleInputChange}
                                      style={{ paddingLeft: "8px" }}
                                      placeholder="Enter ZIP code"
                                    />
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      City
                                    </label>
                                    <input
                                      type="text"
                                      name="city"
                                      value={formDataNew?.city}
                                      onChange={handleInputChange}
                                      style={{ paddingLeft: "8px" }}
                                      placeholder="Enter City"
                                    />
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      Country
                                    </label>
                                    <select
                                      name="billing_country"
                                      value={formDataNew?.billing_country}
                                      onChange={handleInputChange}
                                      id="car_model_estimation_car_brand_id"
                                      class=" labelwidth owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                      data-url-for-models="/car_models/models"
                                    >
                                      {rentYourCar?.country?.map((val) => (
                                        <option value={val.id}>
                                          {val.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="edit-form">
                                    <label
                                      class="cobalt-FormField__Label labelwidth"
                                      for="car_brand_id"
                                    >
                                      Make
                                    </label>
                                    <select
                                      name="billing_state"
                                      value={formDataNew?.billing_state}
                                      onChange={handleInputChange}
                                      id="car_model_estimation_car_brand_id"
                                      class=" labelwidth owner_homepage_hero_estimation_form__item labelwidth  js_car_model_estimation_brand_select js_show_placeholder js_submit_if_other"
                                      data-url-for-models="/car_models/models"
                                    >
                                      {rentYourCar?.state?.map((val) => (
                                        <option value={val.id}>
                                          {val.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                              
</>


  );
};

export default EditBillingAddress;
