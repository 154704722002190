import React, { useContext, useState } from "react";
import { DataContext } from "../../../ContextApi";
import { useEffect } from "react";

const EditCancelationCondition = ({formData,id}) => {
    const {rentYourCar}=useContext(DataContext);
    const [cancelation,setCancelation]=useState([])
    useEffect(() => {
      setCancelation(formData?.cancellation_id
      );
    }, [formData])
  return (
<>
<div
          className="cobalt-CheckmarkField"
          data-form-checkmark-method="is_pro"
        >
          <div>
            {rentYourCar?.cancel_rule.map((val) => (
              <div className="discount-option">
                <input
                  type="radio"
                  name="cancelation"
                  required
                    // checked={cancelation?.includes(val?.cancel_id)}
                  value={val?.cancel_id}
                    onChange={(e) =>
                      setCancelation(e.target.value)
                    }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">Flexible</span> */}
                  <div className="discount-info">
                    <strong>{val?.cancellation_rule_title}</strong>
                    <p>{val?.cancellation_rule}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
</>  


);
};

export default EditCancelationCondition;
