import React, { useContext, useRef, useState } from "react";
import image1 from "../image/png/cp_main_img.png";
import image2 from "../image/png/icon1.png";
import image3 from "../image/png/icon2.png";
import image4 from "../image/png/icon3.png";
import image5 from "../image/png/icon4.png";
import image6 from "../image/png/icon5.png";
import image7 from "../image/png/icon6.png";
import image8 from "../image/png/main_img3.jpg";
import image9 from "../image/png/Looking_ic.png";
import image10 from "../image/png/Looking_ic1.png";
import image11 from "../image/png/logo.svg";
import "./newUiCompany.css";
import { addCompany } from "../api";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../ContextApi";
import ReusableFeedback from "./ReusableFeedback";
import ReusableFaq from "./ReusableFaq";
import ModalReusable from "../ReusableComponent/ModalReusable";
const inputFields = [
  { name: "company_name", label: "MY COMPANY", type: "text", required: true },
  { name: "admin_name", label: "MY NAME", type: "text", required: true },
  {
    name: "company_email",
    label: "E-MAIL",
    type: "email",
    required: true,
    placeholder: "your@email.com",
  },
  // {
  //   name: "admin_email",
  //   label: "ADMIN-MAIL",
  //   type: "email",
  //   required: true,
  //   placeholder: "your@email.com",
  // },
  { name: "company_phone", label: "PHONE", type: "text", required: true },
  { name: "password", label: "PASSWORD", type: "password", required: true },

  { name: "company_address", label: "ADDRESS", type: "text", required: false },
  { name: "company_city", label: "CITY", type: "text", required: false },
  { name: "company_country", label: "COUNTRY", type: "text", required: false },
  {
    name: "company_email",
    label: "E-MAIL",
    type: "email",
    required: true,
    placeholder: "your@email.com",
  },
];
const NewUiCompany = () => {
  const [formValues, setFormValues] = useState({
    company_name: "",
    admin_name: "",
    company_email: "",
    company_phone: "",
    company_address: "",
    company_city: "",
    company_country: "Senegal",
    // admin_email: "",
    company_type: "2",
  });
  const scrollRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const scrollToRef = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { openDialog, feedback } = useContext(DataContext);
  const handleChangeRadio = (names, value) => {
    setFormValues({
      ...formValues,
      [names]: value,
    });
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    // e.preventDefault();
    let payload = { ...formValues };
    addCompany(payload, openDialog, navigate, handleClose);
  };

  return (
    <body
      style={{ height: "auto", background: "#f1f1f1" }}
      id="dashboard-cars-show"
    >
      <div class="js_site_content site_content">
        <div class="section main-section section--grid section--center border_none">
          <div class="wr titleAlign-- driver_main">
            <div class="row align-items-center">
              <div class="col-md-6 mb-3">
                <h3>Make your business trips easier</h3>
                <p>Access vehicles available throughout France 24/7</p>
                <a onClick={scrollToRef} class="btn driver_btn">
                  Create my Business Space
                </a>
              </div>
              <div class="col-md-6 mb-3">
                <img style={{ width: "100%" }} src={image1} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="section main-section section--grid section--center border_none">
          <div class="wr titleAlign-- ">
            <div class="row mb-4 d-flex justify-content-center">
              <div class="col-md-8 mb-3 make-money">
                <h4>Car sharing for professionals</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-3">
                <div class="schedule_card">
                  <img src={image2} alt="" />
                  <h3>Save time</h3>
                  <p>
                    A quick and digital reservation. An inventory carried out in
                    5 minutes.
                  </p>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div class="schedule_card">
                  <img src={image3} alt="" />
                  <h3>100% guaranteed journeys</h3>
                  <p>
                    Each rental is fully insured by Axa, and covered by 24/7
                    roadside assistance.
                  </p>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div class="schedule_card">
                  <img src={image4} alt="" />
                  <h3>Customer service available 7 days a week</h3>
                  <p>
                    So that you are never left by the wayside, our team is here
                    to listen to you.
                  </p>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div class="schedule_card">
                  <img src={image5} alt="" />
                  <h3>Overlo chosen car</h3>
                  <p>
                    So that you are never left by the wayside, our team is here
                    to listen to you.
                  </p>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div class="schedule_card">
                  <img src={image6} alt="" />
                  <h3>100% free</h3>
                  <p>
                    Opening and managing your account without additional costs
                    and without commitment
                  </p>
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <div class="schedule_card">
                  <img src={image7} alt="" />
                  <h3>Popular with businesses of all sizes</h3>
                  <p>
                    Whether you are a company, a self-employed person or an
                    association
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section main-section section--grid section--center border_none">
          <div class="wr titleAlign-- ">
            <div class="Get_started">
              <div class="row">
                <div class="col-md-7 py-4">
                  <h3 class="mb-4">Features dedicated to businesses</h3>
                  <ul>
                    <li>
                      <h4>Simplified accounting</h4>
                      <p>
                        Just tell us which city you'd like to drive in and the
                        type of license you have. We'll email you with your
                        next steps.
                      </p>
                    </li>
                    <li>
                      <h4>Centralized payment</h4>
                      <p>
                        A single payment method for travel for all your team
                        members.
                      </p>
                    </li>
                    <li>
                      <h4>Managing your team</h4>
                      <p>
                        Add and remove your team members as you wish and without
                        limits
                      </p>
                    </li>
                    <li>
                      <h4>Or Connect</h4>
                      <p>
                        Once your Getaround rental starts, the car information
                        will appear in the Uber Driver app. Go online and start
                        earning!
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="col-md-5">
                  <img
                    style={{ width: "100%" }}
                    class="w-100"
                    src={image8}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={scrollRef}
          class="section main-section section--grid section--center border_none"
        >
          <div class="wr titleAlign-- ">
            <div class="row Driver-extras">
              <div class="col-md-12 mb-4">
                <h3>
                  You are already a professional or want to become one with
                  Overlo?
                </h3>
                <p>What we do for you in word</p>
              </div>
              <div class="col-md-6 mb-4">
                <div
                  class="Driver_card"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Are You Looking</h4>
                    <small class="mb-3 d-block">For a Car ?</small>
                    <p>
                      We are committed to providing our customers with
                      exceptional service.
                    </p>
                    <button
                      onClick={() => {
                        setOpenModal(true);
                        handleChangeRadio("company_type", 1);
                      }}
                      style={{
                        borderRadius: "40px",
                        padding: "15px",
                        cursor: "pointer",
                      }}
                      class="btn driver_btn"
                    >
                      Renting Company
                    </button>
                  </div>
                  <div>
                    <img src={image9} alt="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div
                  class="Driver_card"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Do You Want to</h4>
                    <small class="mb-3 d-block">Sell a Car ?</small>
                    <p>
                      We are committed to providing our customers with
                      exceptional service.
                    </p>
                    <button
                      onClick={() => {
                        setOpenModal(true);
                        handleChangeRadio("company_type", 2);
                      }}
                      style={{
                        borderRadius: "40px",
                        padding: "15px",
                        cursor: "pointer",
                      }}
                      class="btn driver_btn"
                    >
                      Rental Company
                    </button>
                  </div>
                  <div>
                    <img src={image10} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section main-section section--grid section--center border_none">
          <div class="wr titleAlign-- ">
            <div class="col-md-12 feedback_user mb-4">
              <h3>What client love about working with us</h3>
            </div>
            <div class="row align-items-center col-md-12">
              <div class="col-md-6">
                <div class="feedback">
                  <h3>Tansu</h3>
                  <p>
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.”
                  </p>
                  <div class="user_main">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="21"
                        cy="21"
                        r="21"
                        fill="url(#pattern0_3068_5989)"
                      />
                      <defs>
                        <pattern
                          id="pattern0_3068_5989"
                          patternContentUnits="objectBoundingBox"
                          width="1"
                          height="1"
                        >
                          <use transform="scale(0.0185185)" />
                        </pattern>
                      </defs>
                    </svg>
                    <div>
                      <h5>John Doe</h5>
                      <p>Director</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="feedback">
                  <h3>Tansu</h3>
                  <p>
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.”
                  </p>
                  <div class="user_main">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="21"
                        cy="21"
                        r="21"
                        fill="url(#pattern0_3068_5989)"
                      />
                      <defs>
                        <pattern
                          id="pattern0_3068_5989"
                          patternContentUnits="objectBoundingBox"
                          width="1"
                          height="1"
                        >
                          <use transform="scale(0.0185185)" />
                        </pattern>
                      </defs>
                    </svg>
                    <div>
                      <h5>John Doe</h5>
                      <p>Director</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReusableFeedback feedback={feedback?.result} />
        <ReusableFaq />
      </div>
      <ModalReusable
        open={openModal}
        handleClose={handleClose}
        handleOperation={handleSubmit}
        buttonText={"Create my Business Space"}
        modalHeader={"Create Company"}
      >
        <div
          style={{
            height: "50vh",
            overflow: "auto",
            padding: "20px",
          }}
        >
          <div
            id="form"
            class=" section--grid section--center dark"
            style={{
              border: "none",
            }}
          >
            <div class="wr titleAlign--">
              <div class="titleWrapper"></div>
              <div
                class="formWrapper"
                style={{
                  borderRadius: "3px",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                {/* <div className="fontfamily" data-form-checkmark-method="is_pro">
               
                <div style={{ display: "flex", gap: "20px" }}>
                  <label className="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      className="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="2"
                      checked={formValues.company_type === "2"}
                      name="company_type"
                      id="car_is_pro_true"
                      onChange={handleChange}
                    />
                    <span className="cobalt-CheckmarkField__Checkmark"></span>
                    <span className="cobalt-CheckmarkField__Label">Rental</span>
                  </label>
                  <label className="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      className="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="1"
                      checked={formValues.company_type === "1"}
                      name="company_type"
                      id="car_is_pro_false"
                      onChange={()=>handleChange("company_type,1")}
                    />
                    <span className="cobalt-CheckmarkField__Checkmark"></span>
                    <span className="cobalt-CheckmarkField__Label">
                      Renting
                    </span>
                  </label>
                </div>
              </div> */}
                <div
                // id="cf-a788qic6ecm0mdsy"
                // className="form"
                // style={{
                //   "--cf-bg": "#fafafa",
                //   "--cf-color": "#000",
                //   "--cf-placeholder": "#bbb",
                //   "--cf-borderColor": "rgba(126, 126, 126, 0.3)",
                // }}
                // data-siteid="8om2m5yd35wf"
                // data-formid="form_bHpHCmPSyakpdpqK"
                // onSubmit={handleSubmit}
                >
                  {inputFields.map(
                    (field, index) =>
                      (open || index < 5) && (
                        <div className="formRow" key={field.name}>
                          <div
                            className="cf-field"
                            data-id={field.name.toUpperCase()}
                            data-label={field.label}
                            data-required={field.required}
                            data-field={field.type}
                          >
                            <label style={{ verticalAlign: "inherit" }}>
                              {field.label}{" "}
                              <span
                                style={{
                                  verticalAlign: "inherit",
                                  color: "rgb(255 180 0)",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type={field.type}
                              name={field.name}
                              value={formValues[field.name]}
                              placeholder={field.placeholder || ""}
                              onChange={handleChange}
                              required={field.required}
                              disabled={field.name === "company_country"}
                            />
                          </div>
                        </div>
                      )
                  )}

                  {/* <div className="ctaWrapper">
                  <button className="btn primary btn--large" type="submit">
                    Create my Business Space
                  </button>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalReusable>
    </body>
  );
};

export default NewUiCompany;
