import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../ContextApi";
import EditConfirmation from "./EditConfirmation";

const EditServiceOffered = ({ id, formData }) => {
  const { rentYourCar } = useContext(DataContext);
  const [selectedOption, setSelectedOption] = useState("");

  const [driverOption, setDriverOption] = useState([]);
  const getDriverOptionIds = (driverOptions) => {
    // Ensure driverOptions is an array, and extract driver_option_id from each option
    return driverOptions.map((option) => option.driver_option_id);
  };

  const handleFeatureChange = (e, featureId) => {
    if (e.target.checked) {
      setDriverOption([...driverOption, featureId]);
    } else {
      setDriverOption(driverOption.filter((id) => id !== featureId));
    }
  };
  useEffect(() => {
    setDriverOption(getDriverOptionIds(formData?.driver_option));
    setSelectedOption(formData?.booking_option_id);
  }, [formData]);
  console.log("handleFeatureChange", driverOption);
  return (
    <div className="cobalt-CheckmarkField" data-form-checkmark-method="is_pro">
      {rentYourCar?.driver_option?.map((val) => (
        <label className="cobalt-CheckmarkField__LabelWrapper">
          <input
            className="cobalt-CheckmarkField__Input"
            type="checkbox"
            // required={selectDataCaseFour?.driver_option_id?.length>0?false :true}

            checked={driverOption?.includes(val.driver_option_id)}
            onChange={(e) => handleFeatureChange(e, val.driver_option_id)}
            value={val?.driver_option_id}
          />
          <span className="cobalt-CheckmarkField__Checkmark"></span>
          <span className="cobalt-CheckmarkField__Label">
            {val?.driver_option}
          </span>
        </label>
      ))}
      <h1 style={{ marginTop: "10px", width: "100%" }} class="h1now">
        Confirmation mode
      </h1>
      <div
        className="cobalt-CheckmarkField"
        data-form-checkmark-method="is_pro"
      >
        {/* {rentYourCar?.booking_option?.map((val) => ( */}
        <div>
          <div
            className={`option-item ${selectedOption == "1" ? "selected" : ""}`}
            onClick={() => setSelectedOption("1")}
          >
            <div className="option-content">
              <span className="option-title">Use instant booking</span>
              <p className="option-description">
                Travelers can book automatically.
              </p>
            </div>
            <span className="option-icon">⚡</span>
          </div>

          <div
            className={`option-item ${selectedOption == "2" ? "selected" : ""}`}
            onClick={() => setSelectedOption("2")}
          >
            <div className="option-content">
              <span className="option-title">Accept or decline requests</span>
              <p className="option-description">
                Travelers must make a reservation request.
              </p>
            </div>
            <span className="option-icon">💬</span>
          </div>
        </div>
        {/* ))} */}
      </div>{" "}
      {/* <div style={{ marginLeft: "auto", marginTop: "20px" }}>
        {" "}
        <button
          class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"
          data-car-id="1475829"
          // onClick={handleFormSubmit}
        >
          <span>Save</span>
        </button>
      </div> */}
    </div>
  );
};

export default EditServiceOffered;
