import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./unavailbilty.css";
import ModalReusable from "../../ReusableComponent/ModalReusable";
import pickup from "../../image/png/pickup.svg";
import drop from "../../image/png/drop.svg";

const Unavailability = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSaveUnavailable,
  highlightUnavailableDates,
  border
}) => {
  // Set initial selected dates
  const [selectedDates, setSelectedDates] = useState([]);

  // Handle date selection
  const handleDateSelect = (date) => {
    const isDateSelected = selectedDates.some(
      (d) => d.getTime() === date.getTime()
    );

    if (isDateSelected) {
      // Remove the date if it is already selected
      setSelectedDates(selectedDates.filter((d) => d.getTime() !== date.getTime()));
    } else {
      // Add the date if it is not selected
      setSelectedDates([...selectedDates, date]);
    }
  };

  const handleSaveClick = () => {
    handleSaveUnavailable(selectedDates); // Pass selected dates to the save handler
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <DatePicker
          selected={null} // Leave this null to not highlight any specific date by default
          onSelect={handleDateSelect} // Called whenever a date is selected
          calendarClassName="custom-width-calendar" // Apply custom class here
          inline
          monthsShown={1}
          highlightDates={selectedDates} // Highlight the selected dates
        />
      </div>


      {/* Save Button */}
      <div style={{display:"flex",flexDirection:"row",gap:"10px"}}>
{selectedDates.length!==0?
      <button
        onClick={handleSaveClick}
        class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"

        disabled={selectedDates.length === 0} // Button enabled only if dates are selected
        style={{
          backgroundColor: selectedDates.length > 0 ? "" : "#ccc",
        
          cursor: selectedDates.length > 0 ? "pointer" : "not-allowed",
         
        }}
      >
        Block
      </button>
      :selectedDates.length!==0?

      <button
        // onClick={handleSaveClick}
        class="cob-Button cob-Button__default cob-Button__default--primary cob-Button--fullWidth js_car_request_instant_booking_cta"

        disabled={selectedDates.length > 0} // Button enabled only if dates are selected
        style={{
          backgroundColor: selectedDates.length > 0 ? "#ccc" : "",
         
          cursor: selectedDates.length > 0 ? "pointer" : "not-allowed",
        
        }}
      >
        UnBlock
      </button>
      :""
}

      </div>

    </>
  );
};

export default Unavailability;
