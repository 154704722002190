import React, { useContext, useState } from "react";
import { DataContext } from "../../../ContextApi";
import { useEffect } from "react";

const EditCarReplace = ({formData,id}) => {
    const {rentYourCar}=useContext(DataContext)
    const [replace,setReplace]=useState([])
    useEffect(() => {
      setReplace([formData?.car_replace_id]
      );
    }, [formData])
    console.log("cancelationRule",replace)
  return (
<>
<div
          className="cobalt-CheckmarkField"
          data-form-checkmark-method="is_pro"
        >
            <div
            className="cobalt-CheckmarkField"
            data-form-checkmark-method="is_pro"
          >
            <div>
              <div className="discount-option">
                <input
                  type="radio"
                  required
                  name="car_replace"
                  checked={replace?.includes("1")}
                  value="1"
                    onChange={(e) =>
                      setReplace(e.target.value)
                    }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">Flexible</span> */}
                  <div className="discount-info">
                    <strong>Replacement</strong>
                    <p>
                    Possible replacement of the vehicle.
                    </p>
                  </div>
                </div>
              </div>

              <div className="discount-option">
                <input
                  type="radio"
                  required
                  name="car_replace"
                  checked={replace?.includes("2")}
                  value="2"
                    onChange={(e) =>
                      setReplace(e.target.value)
                    }
                />
                <div className="discount-details">
                  {/* <span className="discount-percentage">10%</span> */}
                  <div className="discount-info">
                    <strong>Repair</strong>
                    <p>
                    Possible towing of the vehicle and upcoming repairs.
                    </p>
                  </div>
                </div>
              </div>
</div>

            </div>
        </div>
</>  
);
};

export default EditCarReplace;
