import React,{useState,useContext} from "react";
import { addMoreImageArray } from "../../../ExportFiles";
import { DataContext } from "../../../ContextApi";
import { carImageUpload, deleteImageUpload, getCarImages } from "../../../api";

const EditPicture = ({id}) => {
    const [loading, setLoading] = useState({});
    const { imageArray, setImageArray } = useContext(DataContext);
    const [images, setImages] = useState(addMoreImageArray.map(() => null));
    const handleFileUpload = async (file, index) => {
        const formData = new FormData();
        formData.append("file_name", file);
        formData.append("image_id", index);
        formData.append("vehicle_id",id)
    
        setLoading((prevLoading) => ({ ...prevLoading, [index]: true }));
        try {
          const response = await carImageUpload(formData);
          console.log("File uploaded:", response.data);
    
          setImages((prevImages) => {
            const newImages = [...prevImages];
            newImages[index] = response.data.response; // Store the response data
            return newImages;
          });
          getCarImages(id, setImageArray, setLoading);
        } catch (error) {
          console.error(
            "Error uploading file:",
            error.response ? error.response.data : error.message
          );
        } finally {
          setLoading(false);
        }
      };
    
      const handleDeleteImage = (imageId, index) => {
        setLoading((prevLoading) => ({ ...prevLoading, [index]: true }));
        let payload = { vehicle_id: id, image_id: imageId };
    
        deleteImageUpload(payload, setLoading, getCarImages, setImageArray, id);
      };
  return (
  <div>
    {addMoreImageArray.map((val, index) => (
                                      <>
                                        <hr class="car_picture_uploader__advice_divider" />
                                        <div
                                          class="js_car_picture_manager"
                                          data-base-count="4"
                                          data-car-photos-order-path="/cars/1514586/car_photos/order"
                                          data-maximum-extra-count="4"
                                        >
                                          <h3 class="c-text-section-heading c-mb-md">
                                            {val?.header}
                                          </h3>
                                          <div
                                            class="js_car_picture_uploader"
                                            data-car-photo-path="/cars/1514586/car_photo"
                                          >
                                            <div class="c-mb-md">
                                              <div class="js_car_picture_uploader_empty">
                                                <div class="row">
                                                  <div class="col-xs-12 col-md-12">
                                                    {loading[val.id] ? (
                                                      <div class="car_picture_uploader__wrapper">
                                                        <div
                                                          class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_spinner"
                                                          hidden=""
                                                        >
                                                          <div class="car_picture_uploader__placeholder_content">
                                                            <div class="cobalt-Spinner">
                                                              <span></span>
                                                              <span></span>
                                                              <span></span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <>
                                                        {imageArray[
                                                          `image_${index + 1}`
                                                        ] ? (
                                                          <div class="car_picture_uploader__wrapper">
                                                            <div class="car_picture_uploader__actions">
                                                              <div
                                                                onClick={() =>
                                                                  handleDeleteImage(
                                                                    index + 1
                                                                  )
                                                                }
                                                                class="car_picture_uploader__actions_destroy js_car_picture_uploader_destroy"
                                                              >
                                                                <svg
                                                                  width="24"
                                                                  height="24"
                                                                  viewBox="0 0 24 24"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <g
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                  >
                                                                    <path
                                                                      d="M19,4 L15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 Z M6,19 C6,20.1045695 6.8954305,21 8,21 L16,21 C17.1045695,21 18,20.1045695 18,19 L18,7 L6,7 L6,19 Z"
                                                                      fill="#b4bbc6"
                                                                    ></path>
                                                                  </g>
                                                                </svg>
                                                              </div>
                                                            </div>
                                                            <div class="car_picture_uploader__image js_car_picture_uploader_site">
                                                              <img
                                                                srcset={
                                                                  imageArray[
                                                                    `image_${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                }
                                                                style={{
                                                                 
                                                                  objectFit:
                                                                    "cover",
                                                                    height:"206px",width:"100%"
                                                                }}
                                                                src={
                                                                  imageArray[
                                                                    `image_${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                }
                                                                alt={
                                                                  imageArray[
                                                                    `image_${
                                                                      index + 1
                                                                    }`
                                                                  ]
                                                                }
                                                              />
                                                              {/* <img srcset="https://drivy.gumlet.io/uploads/originals/5c4001b3fa204700bd6aa7b1737897bd.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=198&amp;mode=crop&amp;w=352 1x, https://drivy.gumlet.io/uploads/originals/5c4001b3fa204700bd6aa7b1737897bd.jpg?compress=true&amp;dpr=2&amp;fm=auto&amp;h=198&amp;mode=crop&amp;w=352 2x" src="https://drivy.gumlet.io/uploads/originals/5c4001b3fa204700bd6aa7b1737897bd.jpg?compress=true&amp;dpr=1&amp;fm=auto&amp;h=198&amp;mode=crop&amp;w=352"/> */}
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div class="car_picture_uploader__wrapper">
                                                            <label class="car_picture_uploader__placeholder js_car_picture_uploader_placeholder_text">
                                                              <input
                                                                class="car_picture_uploader__file-input js_car_picture_uploader_file_input"
                                                                name="file"
                                                                type="file"
                                                                onChange={(e) =>
                                                                  handleFileUpload(
                                                                    e.target
                                                                      .files[0],
                                                                    val.id
                                                                  )
                                                                }
                                                              />
                                                              <div class="car_picture_uploader__placeholder_content">
                                                                <div class="c-text-body-md c-text-subdued c-mb-xs">
                                                                  {val?.text}
                                                                </div>
                                                                <svg
                                                                  width="32"
                                                                  height="32"
                                                                  viewBox="0 0 24 24"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <g
                                                                    fill="none"
                                                                    fill-rule="evenodd"
                                                                    stroke="none"
                                                                    stroke-width="1"
                                                                  >
                                                                    <path
                                                                      d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 L11,7 L11,11 Z"
                                                                      fill="#b4bbc6"
                                                                    ></path>
                                                                  </g>
                                                                </svg>
                                                              </div>
                                                            </label>
                                                          </div>
                                                        )}
                                                      </>
                                                    )}

                                                    {/* waiting */}
                                                    {/* <div
                                    class="car_picture_uploader__callout js_car_picture_uploader_error_container"
                                    hidden="true"
                                  >
                                    <div class="cobalt-Callout cobalt-Callout--error js_car_picture_uploader_error_text"></div>
                                  </div> */}
                                                  </div>
                                                  <div   class="col-xs-6 col-md-6">
                                                    <div class="row car_picture_uploader__description_container">
                                                      <div  class="col-xs-6 col-md-12">
                                                        <div class="car_picture_uploader__description_text">
                                                          <p>
                                                            {val?.description}
                                                          </p>
                                                        
                                                        </div>
                                                      </div>
                                                      <div class="col-xs-6 col-md-7">
                                                        <a style={{display:"none"}}
                                                          class="car_picture_uploader__description_image to_right"
                                                          data-modalid="js_see_example_car_picture_front_popin"
                                                          href="https://getaround.com/dashboard/cars/1514586/photos?origin=checklist#js_see_example_car_picture_front_popin"
                                                          data-modal-inited="true"
                                                        >
                                                          <img
                                                            srcset={val?.img}
                                                            src={val?.img}
                                                          />
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}

  </div>
)
};

export default EditPicture;
