import React, { useContext, useState } from "react";
import { DataContext } from "../../../../ContextApi";
import { handleChange } from "../../../../ExportFiles";

const Milage = () => {
  const {
    setSelectDataCaseTwo,
    selectDataCaseTwo,
    rentYourCar,
    selectStateWithoutDriverCase,
    setSelectStateWithoutDriverCase,
    selectStateDriverCase,
    setSelectStateDriverCase,
    setSelectDriverCase,
    selectDriverCase,
    selectDataCaseFour,
  } = useContext(DataContext);
  const [selectedExtraId, setSelectedExtraId] = useState(null);
  const [selectRadioId, setSelectedRadioId] = useState(
    selectDataCaseFour?.driver_option_id[0]
  );

  const handlePricingChange = (event) => {
    setSelectedExtraId(event.target.value);
  };
  console.log("selectedfour", selectStateDriverCase,selectStateWithoutDriverCase);
  return (
    <>
      <div class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main">
        <div class="c-text-section-heading c-mb-xs">List my car</div>
        <div class="c-text-title-md ">Select to enter price</div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {selectDataCaseFour?.driver_option_id?.map((id) => (
            <div key={id}>
              <div className="cobalt-CheckmarkField">
                <label className="cobalt-CheckmarkField__LabelWrapper">
                  <input
                    className="cobalt-CheckmarkField__Input"
                    type="radio"
                    value={id}
                    checked={selectRadioId === id}
                    onChange={() => setSelectedRadioId(id)}
                    id={`driver_option_${id}`}
                  />
                  <span className="cobalt-CheckmarkField__Checkmark"></span>
                  <span className="cobalt-CheckmarkField__Label">
                    {id === 2
                      ? "Without Driver"
                      : id === 1
                      ? "With Driver"
                      : "Airport Pickup"}
                  </span>
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectRadioId && (
        <div>
          {selectRadioId === 2 && (
            <div
              id="1"
              class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main"
            >
              <div class="c-text-section-heading c-mb-xs">List my car</div>
              <div class="c-text-title-md c-mb-lg">Without driver</div>

              <div
                class="cobalt-FormField"
                data-form-field-method="plate_number"
              >
                <label class="cobalt-FormField__Label" for="car_plate_number">
                 Rental Price{" "}
                </label>
                <div
                  style={{
                    display: "flex",
                    fontSize: "25px",
                    fontWeight: "800",
                    gap: "10px",
                  }}
                  class="cobalt-TextField"
                >
                  $
                  <input
                    required
                    class="cobalt-TextField__Input"
                    type="number"
                    name="price"
                    style={{
                      fontSize: "25px",
                      fontWeight: "800",
                      width: "20%",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "e" || e.key === "+") {
                        e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (value >= 0) {
                        handleChange(
                          e,
                          setSelectDataCaseTwo,
                          selectDataCaseTwo,
                          "caseTwo"
                        );
                      }
                    }}
                    value={selectDataCaseTwo.price}
                  />
                </div>
              </div>

              <div class="cobalt-FormField">
                <label class="cobalt-FormField__Label" for="car_transmission">
                  Extra Pricing
                </label>
                <div
                  class="cobalt-CheckmarkField"
                  data-form-checkmark-method="transmission"
                >
                  <label class="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      class="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="per_km"
                      checked={selectedExtraId === "per_km"}
                      onChange={handlePricingChange}
                      id="car_transmission_1"
                    />
                    <span class="cobalt-CheckmarkField__Checkmark"></span>
                    <span class="cobalt-CheckmarkField__Label">Per Km</span>
                  </label>
                </div>
                <div
                  class="cobalt-CheckmarkField"
                  data-form-checkmark-method="transmission"
                >
                  <label class="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      class="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="region" // Example value, replace with your actual value
                      checked={selectedExtraId === "region"} // Adjust this check as per your logic
                      onChange={handlePricingChange}
                      id="car_transmission_1"
                    />
                    <span class="cobalt-CheckmarkField__Checkmark"></span>
                    <span class="cobalt-CheckmarkField__Label">Region</span>
                  </label>
                </div>
              </div>
              {selectedExtraId === "per_km" ? (
                <>
                  <div
                    class="cobalt-FormField"
                    data-form-field-method="plate_number"
                  >
                    <label
                      class="cobalt-FormField__Label"
                      for="car_plate_number"
                    >
                      Maximum kilometer offer by day{" "}
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        required
                        class="cobalt-TextField__Input"
                        type="number"
                        name="max_km_offered_per_day"
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e" || e.key === "+") {
                            e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                          }
                        }}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Allow only positive numbers
                          if (value >= 0) {
                            handleChange(
                              e,
                              setSelectDataCaseTwo,
                              selectDataCaseTwo,
                              "caseTwo"
                            );
                          }
                        }}
                        value={selectDataCaseTwo.max_km_offered_per_day}
                      />
                    </div>
                  </div>
                  <div
                    class="cobalt-FormField"
                    data-form-field-method="plate_number"
                  >
                    <label
                      class="cobalt-FormField__Label"
                      for="car_plate_number"
                    >
                      Maximum maximum kilometer exceeded{" "}
                    </label>
                    <div class="cobalt-TextField">
                      <input
                        required
                        class="cobalt-TextField__Input"
                        type="number"
                        name="price_per_km_max_exceeded"
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e" || e.key === "+") {
                            e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                          }
                        }}
                        onChange={(e) => {
                          const value = e.target.value;

                          // Allow only positive numbers
                          if (value >= 0) {
                            handleChange(
                              e,
                              setSelectDataCaseTwo,
                              selectDataCaseTwo,
                              "caseTwo"
                            );
                          }
                        }}
                        value={selectDataCaseTwo.price_per_km_max_exceeded}
                      />
                    </div>
                  </div>
                </>
              ) : selectedExtraId === "region" ? (
                <div
                  class="cobalt-FormField"
                  data-form-field-method="plate_number"
                >
                  <div class="cobalt-CheckablePillsGroup">
                    {rentYourCar?.state?.map((val) => (
                      <div style={{ display: "flex" }}>
                        <div
                          class="cobalt-CheckablePillField cobalt-CheckablePillField--with-icon"
                          data-form-checkmark-method="equipments"
                        >
                          <label class="cobalt-CheckablePillField__LabelWrapper">
                            <input
                              id={`checkable-pill-${val.id}`}
                              className="cobalt-CheckablePillField__Input"
                              type="checkbox"
                              name="states"
                              checked={selectStateWithoutDriverCase?.states?.includes(
                                val?.id
                              )}
                              value={val.id}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  setSelectStateWithoutDriverCase,
                                  selectStateWithoutDriverCase,
                                  "caseWithOutDriver"
                                )
                              }
                            />
                            <span class="cobalt-CheckablePillField__Label">
                              {/* <span class="cobalt-Icon cobalt-Icon--car">
                       
                        <span class="cobalt-Icon cobalt-Icon--car">
                        <img src={val?.image_url} style={{width:"20px",height:"20px"}} />
                      </span>
                      </span> */}
                              {val?.name}
                            </span>
                          </label>
                        </div>

                        {selectStateWithoutDriverCase?.states.includes(
                          val.id
                        ) && (
                          <>
                            {/* <label class="cobalt-FormField__Label" for="car_plate_number">
                    Extra Price for  {val.name}: zone{" "}
                  </label> */}
                            <div class="cobalt-TextField">
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "25px",
                                  fontWeight: "800",
                                  gap: "10px",
                                }}
                                class="cobalt-TextField"
                              >
                                $
                                <input
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "800",
                                    width: "20%",
                                  }}
                                  required
                                  class="cobalt-TextField__Input"
                                  type="number"
                                  name={val?.short_name}
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === "-" ||
                                      e.key === "e" ||
                                      e.key === "+"
                                    ) {
                                      e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                                    }
                                  }}
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    // Allow only positive numbers
                                    if (value >= 0) {
                                      handleChange(
                                        e,
                                        setSelectStateWithoutDriverCase,
                                        selectStateWithoutDriverCase,
                                        "caseWithOutDriver"
                                      );
                                    }
                                  }}
                                  value={
                                    selectStateWithoutDriverCase[val?.short_name] || ""
                                  }

                                  //     value={prices[val.id] || ''}
                                  //   onChange={(e) => handlePriceChange(e, val.id)}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div class="cobalt-Note cobalt-Note--fullWidth">
                This information allows us to ensure the quality and safety of
                our fleet.
              </div>
            </div>
          )}
          {selectRadioId === 1 && (
            <div
              id="2"
              class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main"
            >
              <div class="c-text-section-heading c-mb-xs">List my car</div>
              <div class="c-text-title-md c-mb-lg">With driver</div>

              <div
                class="cobalt-FormField"
                data-form-field-method="plate_number"
              >
                <label class="cobalt-FormField__Label" for="car_plate_number">
                 Rental Price{" "}
                </label>
                <div
                  style={{
                    display: "flex",
                    fontSize: "25px",
                    fontWeight: "800",
                    gap: "10px",
                  }}
                  class="cobalt-TextField"
                >
                  $
                  <input
                    required
                    class="cobalt-TextField__Input"
                    type="number"
                    name="with_driver_price"
                    style={{
                      fontSize: "25px",
                      fontWeight: "800",
                      width: "20%",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "e" || e.key === "+") {
                        e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only positive numbers
                      if (value >= 0) {
                        handleChange(
                          e,
                          setSelectDataCaseTwo,
                          selectDataCaseTwo,
                          "caseTwo"
                        );
                      }
                    }}
                    value={selectDataCaseTwo.with_driver_price}
                  />
                </div>
              </div>
              <div
                class="cobalt-FormField"
                data-form-field-method="plate_number"
              >
                <label class="cobalt-FormField__Label" for="car_plate_number">
                 Driver Price{" "}
                </label>
                <div
                  style={{
                    display: "flex",
                    fontSize: "25px",
                    fontWeight: "800",
                    gap: "10px",
                  }}
                  class="cobalt-TextField"
                >
                  $
                  <input
                    required
                    class="cobalt-TextField__Input"
                    type="number"
                    name="with_driver_price"
                    style={{
                      fontSize: "25px",
                      fontWeight: "800",
                      width: "20%",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "e" || e.key === "+") {
                        e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                      }
                    }}
                    // onChange={(e) => {
                    //   const value = e.target.value;

                    //   // Allow only positive numbers
                    //   if (value >= 0) {
                    //     handleChange(
                    //       e,
                    //       setSelectDataCaseTwo,
                    //       selectDataCaseTwo,
                    //       "caseTwo"
                    //     );
                    //   }
                    // }}
                    // value={selectDataCaseTwo.with_driver_price}
                  />
                </div>
              </div>

              <div class="cobalt-FormField">
                <label class="cobalt-FormField__Label" for="car_transmission">
                  Do you accept travel to other regions for an additional fee?{" "}
                </label>
                <div
                  class="cobalt-CheckmarkField"
                  data-form-checkmark-method="transmission"
                >
                  <label class="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      class="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="yes_region_driver"
                      checked={selectedExtraId === "yes_region_driver"}
                      onChange={handlePricingChange}
                      id="car_transmission_1"
                    />
                    <span class="cobalt-CheckmarkField__Checkmark"></span>
                    <span class="cobalt-CheckmarkField__Label">Yes</span>
                  </label>
                </div>
                <div
                  class="cobalt-CheckmarkField"
                  data-form-checkmark-method="transmission"
                >
                  <label class="cobalt-CheckmarkField__LabelWrapper">
                    <input
                      class="cobalt-CheckmarkField__Input"
                      type="radio"
                      value="no_region_driver" // Example value, replace with your actual value
                      checked={selectedExtraId === "no_region_driver"} // Adjust this check as per your logic
                      onChange={handlePricingChange}
                      id="car_transmission_1"
                    />
                    <span class="cobalt-CheckmarkField__Checkmark"></span>
                    <span class="cobalt-CheckmarkField__Label">No</span>
                  </label>
                </div>
              </div>
              {selectedExtraId === "no_region_driver" ? (
                ""
              ) : selectedExtraId === "yes_region_driver" ? (
                <div
                  class="cobalt-FormField"
                  data-form-field-method="plate_number"
                >
                  <div class="cobalt-CheckablePillsGroup">
                    {rentYourCar?.state?.map((val) => (
                      <div style={{ display: "flex" }}>
                        <div
                          class="cobalt-CheckablePillField cobalt-CheckablePillField--with-icon"
                          data-form-checkmark-method="equipments"
                        >
                          <label class="cobalt-CheckablePillField__LabelWrapper">
                            <input
                              id={`checkable-pill-${val.id}`}
                              className="cobalt-CheckablePillField__Input"
                              type="checkbox"
                              name="states"
                              checked={selectStateDriverCase?.states?.includes(
                                val?.id
                              )}
                              value={val.id}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  setSelectStateDriverCase,
                                  selectStateDriverCase,
                                  "caseWithDriver"
                                )
                              }
                            />
                            <span class="cobalt-CheckablePillField__Label">
                              {/* <span class="cobalt-Icon cobalt-Icon--car">
                       
                        <span class="cobalt-Icon cobalt-Icon--car">
                        <img src={val?.image_url} style={{width:"20px",height:"20px"}} />
                      </span>
                      </span> */}
                              {val?.name}
                            </span>
                          </label>
                        </div>

                        {selectStateDriverCase?.states.includes(val.id) && (
                          <>
                            {/* <label class="cobalt-FormField__Label" for="car_plate_number">
                    Extra Price for  {val.name}: zone{" "}
                  </label> */}
                            <div class="cobalt-TextField">
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: "25px",
                                  fontWeight: "800",
                                  gap: "10px",
                                }}
                                class="cobalt-TextField"
                              >
                                $
                                <input
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "800",
                                    width: "20%",
                                  }}
                                  required
                                  class="cobalt-TextField__Input"
                                  type="number"
                                  name={val?.short_name}
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === "-" ||
                                      e.key === "e" ||
                                      e.key === "+"
                                    ) {
                                      e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                                    }
                                  }}
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    // Allow only positive numbers
                                    if (value >= 0) {
                                      handleChange(
                                        e,
                                        setSelectStateDriverCase,
                                        selectStateDriverCase,
                                        "caseWithDriver"
                                      );
                                    }
                                  }}
                                  value={
                                    selectStateDriverCase[val?.short_name] || ""
                                  }

                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div class="cobalt-Note cobalt-Note--fullWidth">
                This information allows us to ensure the quality and safety of
                our fleet.
              </div>
            </div>
          )}
          {selectRadioId === 3 && (
            <div
              id="3"
              class="cobalt-Card__Section cobalt-Card__Section--divided car_wizard__main"
            >
              <div class="c-text-section-heading c-mb-xs">List my car</div>
              <div class="c-text-title-md c-mb-lg">Airport Pick and Drop</div>

              <div
                class="cobalt-FormField"
                data-form-field-method="plate_number"
              >
                <label class="cobalt-FormField__Label" for="car_plate_number">
                  Price for airport drop-off{" "}
                </label>
                <div
                  style={{
                    display: "flex",
                    fontSize: "25px",
                    fontWeight: "800",
                    gap: "10px",
                  }}
                  class="cobalt-TextField"
                >
                  $
                  <input
                    required
                    class="cobalt-TextField__Input"
                    type="number"
                    name="price_airport_dropoff"
                    style={{
                      fontSize: "25px",
                      fontWeight: "800",
                      width: "20%",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "e" || e.key === "+") {
                        e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only positive numbers
                      if (value >= 0) {
                        handleChange(
                          e,
                          setSelectDataCaseTwo,
                          selectDataCaseTwo,
                          "caseTwo"
                        );
                      }
                    }}
                    value={selectDataCaseTwo.price_airport_dropoff}
                  />
                </div>
              </div>
              <div
                class="cobalt-FormField"
                data-form-field-method="plate_number"
              >
                <label class="cobalt-FormField__Label" for="car_plate_number">
                  Price for airport pick-up{" "}
                </label>
                <div
                  style={{
                    display: "flex",
                    fontSize: "25px",
                    fontWeight: "800",
                    gap: "10px",
                  }}
                  class="cobalt-TextField"
                >
                  $
                  <input
                    required
                    class="cobalt-TextField__Input"
                    type="number"
                    name="price_airport_pickup"
                    style={{
                      fontSize: "25px",
                      fontWeight: "800",
                      width: "20%",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "e" || e.key === "+") {
                        e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only positive numbers
                      if (value >= 0) {
                        handleChange(
                          e,
                          setSelectDataCaseTwo,
                          selectDataCaseTwo,
                          "caseTwo"
                        );
                      }
                    }}
                    value={selectDataCaseTwo.price_airport_pickup}
                  />
                </div>
              </div>
              <div
                class="cobalt-FormField"
                data-form-field-method="plate_number"
              >
                <label class="cobalt-FormField__Label" for="car_plate_number">
                  Price for both airport pick-up & drop-off{" "}
                </label>
                <div
                  style={{
                    display: "flex",
                    fontSize: "25px",
                    fontWeight: "800",
                    gap: "10px",
                  }}
                  class="cobalt-TextField"
                >
                  $
                  <input
                    required
                    class="cobalt-TextField__Input"
                    type="number"
                    name="price_airport_pickup"
                    style={{
                      fontSize: "25px",
                      fontWeight: "800",
                      width: "20%",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "e" || e.key === "+") {
                        e.preventDefault(); // Prevent typing negative sign, 'e', or '+'
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only positive numbers
                      // if (value >= 0) {
                      //   handleChange(
                      //     e,
                      //     setSelectDataCaseTwo,
                      //     selectDataCaseTwo,
                      //     "caseTwo"
                      //   );
                      // }
                    }}
                    // value={selectDataCaseTwo.price_airport_pickup}
                  />
                </div>
              </div>
              <div class="cobalt-Note cobalt-Note--fullWidth">
                This information allows us to ensure the quality and safety of
                our fleet.
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Milage;
