import React, { useState, useContext, useRef, useEffect } from "react";
import "../MainComponent/home.css";
import logo from "../image/png/logo.svg";
import drop from "../image/svg/dropdown.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../ContextApi/AuthContext";
import { menuArray } from "../ExportFiles";

const Header = () => {
  const { login, isLoggedIn, logout } = useContext(AuthContext);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({});
  const menuRef = useRef(null);
  const anotherRef = useRef(null);
  const location = useLocation();

  // Check if the pathname includes '/main-menu'
  const isMainMenu = location.pathname.startsWith('/main-menu');

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  const filteredMenuArray = menuArray.filter((val) => {
    let storedRoles = localStorage.getItem("roles");

    if (storedRoles !== null && storedRoles !== undefined) {
      try {
        storedRoles = JSON.parse(storedRoles);
      } catch (error) {
        console.error("Failed to parse JSON from localStorage:", error);
        storedRoles = {}; // Default to an empty object if parsing fails
      }
    } else {
      storedRoles = {}; // Default to an empty object if nothing is stored
    }

    // Assuming 'val' is defined somewhere in your code
    if (val) {
      if (val.id === 9 || val.id === 10) {
        return false;
      }
      return storedRoles[val?.name.toLowerCase()] === "1";
    }
  });
  useEffect(() => {
    if (isMenuOpen && menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();
      setMenuPosition({
        top: 66,
        left: rect.left + window.scrollX,
      });
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const closeMenu = (event) => {
      if (anotherRef.current && !anotherRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", closeMenu);
    } else {
      document.removeEventListener("mousedown", closeMenu);
    }

    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  }, [isMenuOpen]);
  return (
    <header className="header js_header">
      <div className="container">
        <nav className="header__container">
          <a className="header__logo header__logo--rebranded" href="/" title="">
            <img
              className="header__logo-image--darker"
              width="110"
              height="46"
              alt=""
              src={logo}
            />
          </a>
          {isMenuOpen && (
            <div
              data-tippy-root=""
              id="tippy-5"
              ref={anotherRef}
              style={{
                zIndex: 10,
                visibility: "visible",
                position: "absolute",
                top: `${menuPosition.top}px`,
                right: localStorage.getItem("company_id") == -1||isMainMenu ? "0px" : "",
                left:
                  localStorage.getItem("company_id") == -1||isMainMenu
                    ? ""
                    : `${menuPosition.left}px`,
                margin: "0px",
                transform: "translate3d(0, 0, 0)",
              }}
            >
              <div
                className="tippy-box"
                data-state="visible"
                tabIndex="-1"
                data-theme="light cobalt-popover user-menu cobalt-popover--withArrow"
                data-animation="shift-away-subtle"
                data-inertia=""
                role="tooltip"
                data-placement="bottom-end"
                style={{
                  maxWidth: "500px",
                  transitionDuration: "200ms",
                }}
              >
                <div
                  className="tippy-content"
                  data-state="visible"
                  style={{
                    transitionDuration: "200ms",
                  }}
                >
                  <div>
                    <div className="user-menu-popover c-pt-none c-rounded-lg">
                      <div className=" c-border-grey">
                        <div className="cobalt-Card c-border-none">
                          <div className="cobalt-Card__Section">
                            <div className="c-flex c-flex-row c-pt-2xs">
                              <div className="user-menu-popover__avatar-container cobalt-avatar c-mr-sm ">
                                <img
                                  className="user-menu-popover__avatar"
                                  src={
                                    localStorage.getItem("cover_photo") !=
                                    "null"
                                      ? localStorage.getItem("cover_photo")
                                      : logo
                                  }
                                  title="Zubair Zubair"
                                  alt="avatar"
                                />
                              </div>
                              <a className="user-menu-popover__account-link c-flex-1">
                                <div className="c-inline-block c-w-full c-text-title-sm c-text-base c-no-underline">
                                  {localStorage.getItem("name") == "null"
                                    ? "no name"
                                    : localStorage.getItem("name")}
                                </div>
                                <div className="user-menu-popover__account-sub-link c-mt-2xs">
                                  Edit your account
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cobalt-Card c-border-none">
                        {/* {filteredMenuArray.map((val) =>
                        val.id === 9 || val.id === 10 ? null : */}
                        <a
                          className="link_no_style cobalt-Card__Section cobalt-Card__Section--dividedSoft"
                          onClick={() => {
                            navigate(`/main-menu/request`);
                            setIsMenuOpen(false);
                          }}
                        >
                          <div
                            className="c-text-body-md c-text-base c-flex c-flex-row c-items-center"
                            data-spec="rentals"
                          >
                            <div className="c-flex-1">Dashboard</div>
                          </div>
                        </a>
                        <a
                          className="link_no_style cobalt-Card__Section cobalt-Card__Section--dividedSoft"
                          onClick={() => {
                            navigate(`/main-menu/profile/edit-profile`);
                            setIsMenuOpen(false);
                          }}
                        >
                          <div
                            className="c-text-body-md c-text-base c-flex c-flex-row c-items-center"
                            data-spec="rentals"
                          >
                            <div className="c-flex-1">Profile</div>
                          </div>
                        </a>
                        <a
                          className="link_no_style cobalt-Card__Section cobalt-Card__Section--dividedSoft"
                          onClick={() => {
                            // navigate(`/main-menu/profile/edit-profile`);
                            setIsMenuOpen(false);
                          }}
                        >
                          <div
                            className="c-text-body-md c-text-base c-flex c-flex-row c-items-center"
                            data-spec="rentals"
                          >
                            <div className="c-flex-1">Message</div>
                          </div>
                        </a>

                        {/* )} */}
                      </div>
                      <div className="c-bg-white c-rounded-lg c-m-sm c-overflow-hidden">
                        {/* <a className="user-menu__special-link cobalt-Card__Section cobalt-Card__Section--subdued cobalt-Card__Section--dividedSoft link_no_style">
                          <div className="c-text-body-md c-text-base c-flex c-flex-row c-items-center">
                            <div className="c-flex-1">Get $20 credit</div>
                            <div>
                              <span className="cobalt-Icon cobalt-Icon--miscGift cobalt-Icon--colorBase">
                                <svg
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    clipRule="evenodd"
                                    d="M16.238 7.394h4.368c.206 0 .394.166.394.386v2.207c0 .22-.188.386-.394.386h-7.969V7.449h-1.274v2.924h-7.97A.392.392 0 0 1 3 9.987V7.78c0-.22.188-.386.394-.386h4.369c-.638-.092-1.05-.386-1.294-.643-.394-.386-.6-.938-.6-1.508C5.869 4.158 6.675 3 8.156 3c1.069 0 2.213.588 3.413 1.765.072.08.145.156.216.23.075.078.147.154.215.23.131-.147.281-.313.431-.46C13.631 3.588 14.775 3 15.844 3c.675 0 1.275.257 1.706.717.375.405.581.956.581 1.526 0 .57-.206 1.103-.6 1.508-.244.257-.656.551-1.294.643zm-9.094-2.17c0 .46.281.938 1.087.938h2.888c-.806-.9-1.969-1.912-2.981-1.912-.788 0-.994.607-.994.975zm5.719.938c.806-.882 1.968-1.912 2.98-1.912.788 0 .995.607.995.975a.945.945 0 0 1-.244.643c-.188.202-.469.294-.844.294z"
                                    fillRule="evenodd"
                                  ></path>
                                  <path d="M4.088 20.485a.53.53 0 0 0 .525.515h6.768v-9.395H4.087zm8.568.515h6.75a.531.531 0 0 0 .525-.515v-8.88h-7.275z"></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </a> */}
                        <a
                          onClick={() => {
                            logout(navigate);
                            setIsMenuOpen(false);
                          }}
                          data-method="delete"
                          className="user-menu__special-link cobalt-Card__Section cobalt-Card__Section--subdued cobalt-Card__Section--dividedSoft link_no_style"
                        >
                          <div className="c-text-body-md c-text-base c-flex c-flex-row c-items-center">
                            <div className="c-flex-1">Log out</div>
                            <div>
                              <span className="cobalt-Icon cobalt-Icon--logout cobalt-Icon--colorBase">
                                <svg
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.08 15.59 16.67 13H7v-2h9.67l-2.59-2.59L15.5 7l5 5-5 5-1.42-1.41ZM19 3a2 2 0 0 1 2 2v4.67l-2-2V5H5v14h14v-2.67l2-2V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h14Z"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tippy-arrow"
                  style={{
                    position: "absolute",
                    left: localStorage.getItem("company_id") == -1||isMainMenu ? "" : "0px",
                    right:
                      localStorage.getItem("company_id") == -1||isMainMenu ? "27px" : "",
                    transform: "translate3d(10px, 0px, 0px)",
                  }}
                ></div>
              </div>
            </div>
          )}
          <ul className="navigation-bar">
            {!isLoggedIn ? (
              <li
                onClick={() => navigate("/login")}
                className="navbar__item c-hidden sm:c-inline-flex"
              >
                <span className="navlink">Log In</span>
              </li>
            ) : (
              <li
                ref={menuRef}
                onClick={toggleMenu}
                className="navbar__item c-hidden sm:c-inline-flex"
              >
                <div
                  style={{ alignItems: "center" }}
                  className="c-flex c-flex-row"
                >
                  <div className="user-menu-popover__avatar-container cobalt-avatar c-mr-sm ">
                    <img
                      className="user-menu-popover__avatar"
                      src={
                        localStorage.getItem("cover_photo") != "null"
                          ? localStorage.getItem("cover_photo")
                          : logo
                      }
                      title="Zubair Zubair"
                      alt="avatar"
                    />
                  </div>
                  <a className="user-menu-popover__account-link c-flex-1">
                    <div className="c-inline-block c-w-full c-text-title-sm c-text-base c-no-underline">
                      {localStorage.getItem("name") == "null"
                        ? "no name"
                        : localStorage.getItem("name")}
                      <img src={drop} />
                    </div>
                  </a>
                </div>
                {/* <span className="navlink">Menu <img src={drop}/></span> */}
              </li>
            )}
            {localStorage.getItem("company_id") == -1 ||isMainMenu? (
              ""
            ) : (
              <>
                {/* <li className="navbar__item c-hidden sm:c-inline-flex">
                  <span className="navlink">FAQ</span>
                </li> */}
                <li
                  onClick={() => navigate("/company")}
                  className="navbar__item c-hidden sm:c-inline-flex"
                >
                  <span className="navlink">Company</span>
                </li>
                <li
                  onClick={() => navigate("/add_driver")}
                  className="navbar__item c-hidden sm:c-inline-flex"
                >
                  <span className="navlink">Driver</span>
                </li>
                <li className="navbar__item c-hidden sm:c-inline-flex">
                  <span className="navlink">Service</span>
                </li>
                {localStorage.getItem("company_type")==="1"?"":
                <li className="navbar__item navbar__item--centered c-hidden sm:c-inline-flex">
                  <a
                    className="cob-Button cob-Button__default"
                    onClick={() => navigate("/rent-your-car")}
                  >
                    Rent out your car
                  </a>
                </li>
                }
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
