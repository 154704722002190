import React, { useContext, useEffect } from "react";
import Header from "./component/Header";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./component/Footer";
import Home from "./MainComponent/Home";
import Search from "./component/Search";
import { DataContext, DataProvider } from "./ContextApi";
import RentYourCar from "./component/Pages/RentYourCar/RentYourCar";
import Menu from "./component/Pages/MenuPages/Menu";
import Main from "./component/Pages/MenuPages/Register/Main";
import { AuthProvider } from "./ContextApi/AuthContext";
import PrivateRoute from "./PrivateRoute";
import Login from "./component/Login";
import OverAllSection from "./component/Pages/CarDetails/OverallSection";
import Signup from "./component/Signup";
import { Box, Snackbar } from "@material-ui/core";
import BusinessSpace from "./component/BusinessSpace";
import OwnerDetails from "./component/Pages/CarDetails/OwnerDetails";
import Register from "./component/Register";
import Verify from "./component/Verify";
import VerifyForget from "./component/Pages/VerifyForget";
import ForgetPassword from "./component/Pages/ForgetPassword";
import HowWorks from "./component/Pages/HowWorks";
import TypeCars from "./component/Pages/TypeCars";
import NewUiBusinesspace from "./component/NewUiBusinesspace";
import SearchDriver from "./component/SearchDriver";
import MainDriver from "./component/Pages/MenuPages/DriverRegister/MainDriver";
import BusinessSpace2 from "./component/BusinessSpace2";
import NewUiCompany from "./component/NewUiCompany";

function App() {

  return (
   
      
    <DataProvider>
      <AuthProvider>
        <Router>
          <Content />
        </Router>
      </AuthProvider>
    </DataProvider>
  );
}

function Content() {
  const location = useLocation();
  const {vertical, horizontal, open,handleClose,message}=useContext(DataContext)
  const anchorOrigin = {
    vertical: typeof vertical === 'string' ? vertical : 'center',
    horizontal: typeof horizontal === 'string' ? horizontal : 'center',
  };
 
    // Scroll to top when the route changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);
  return (
    <body
    class= {location.pathname ==="/"&&"marketing-page"}
    data-action="pages-homepage-index"
    id="search_show"
  >
    <div className="js_site_content site_content">
      {/* Conditionally render Header based on the current pathname */}
      {location.pathname !== '/login'&&location.pathname !== '/sign-up'&&location.pathname!=='/verify_forgot_password'&&location.pathname!=='/forgot_password' && <Header />}
         <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
    </Box>
      <Routes>
        <Route path="/search" exact element={<Search />} />
        <Route path="/search_driver" exact element={<SearchDriver />} />

        
        <Route path="/login" exact element={<Login />} />
        <Route path="/verify_forgot_password" exact element={<VerifyForget />} />
        <Route path="/forgot_password" exact element={<ForgetPassword />} />

        <Route path="/verify" exact element={<Verify />} />

        <Route path="/sign-up" exact element={<Signup />} />
        <Route path="/owner_detail/:id" exact element={<OwnerDetails />} />
        <Route path="/type/:id" exact element={<TypeCars />} />

        <Route path="/" exact element={<Home />} />
        <Route path="/rent-your-car" exact element={<RentYourCar />} />
        <Route path="/company" exact element={<NewUiCompany  />} />
        <Route path="/new_company" exact element={<NewUiBusinesspace />} />

        <Route path="/add_driver" exact element={<PrivateRoute element={<BusinessSpace2 />} />} />
        <Route path="/add_driver_new" exact element={<PrivateRoute element={<BusinessSpace />} />} />

        <Route path="/register" exact element={<Register />} />
        <Route path="/how-it-works" exact element={<HowWorks />} />

        <Route path="/car-detail/:id" exact element={<OverAllSection />} />

        <Route path="/main-menu/*" exact element={<PrivateRoute element={<Menu />} />} />
        <Route path="/car_wizards/:id/*" element={<PrivateRoute element={<Main />} />} />
        <Route path="/add_driver/:id/*" element={<PrivateRoute element={<MainDriver />} />} />

      </Routes>

      {/* Conditionally render Footer based on the current pathname */}
      {location.pathname !== '/login'&&location.pathname !== '/sign-up'&&location.pathname!=='/verify_forgot_password'&&location.pathname!=='/forgot_password' && <Footer />}
    </div>
    </body>
  );
}

export default App;
